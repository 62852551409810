@import "variables.scss";
@import "mixins.scss";
@import "app/assets/stylesheets/base/foundation-settings";
@import "base/modernize-vars";

.mobile-only {
  @media only screen and (min-width: 1200px) {
    display: none !important;
  }
}
.tray-overlay {
  position: fixed;
  height: 100%;
  z-index: 1000;
  background-color: #0E0F25;
  opacity: 0.8;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  &.show {
    display: unset;
  }
  &:not(.show) {
    display: none;
  }
}
#main-content {
  background-color: #FFF;
  min-height: auto;
}
.container-responsive {
  max-width: unset;
  @media only screen and (max-width: 540px) {
    padding-bottom: 0px;
  }
}
.profile-container-main {
  max-width: 1200px;
  margin: auto;
  &.view-all-slideshows-main {
    .user-stats-amount {
      &.mobile-only {
        display: none;
        @media only screen and (max-width: 1200px) and (min-width: 480px) {
          display: flex;
        }
      }
    }
    .main-content {
      position: relative;
      .sort-buttons {
        position: absolute;
        right: 0;
        top: 0;
        a {
          border: solid 1px #636C8B;
          font-weight: 400;
          font-size: 14px;
          color: #0E0F25;
          padding: 4px 8px;
          background-image: none;
          background-color: #FFF;
          text-shadow: none;
          box-shadow: none;
          &.selected {
            background-color: $blue-dark;
            color: #FFF;
            border-color: $blue-dark;
          }
        }
        @media only screen and (max-width: 600px) {
          position: unset;
          display: flex;
          justify-content: right;
          padding: 6px 0 24px 0;
          a {
            font-size: 16px;
            padding: 6px 18px;
          }
        }
      }
    }
    .slideshow-card {
      width: calc(33.33% - 8px);
      max-width: 100%;
      min-width: 0%;
      min-height: 260px;
      @media only screen and (min-width: 600px) {
        &:nth-child(3n) {
          margin-right: 0px !important;
        }
      }
      @media only screen and (max-width: 600px) {
        max-width: 100%;
        min-width: 0%;
        width: calc(50% - 6px);
        &:nth-child(4n + 4) {
          margin-right: 0px !important;
        }
      }
      @media only screen and (max-width: 480px) {
        min-width: 100%;
        margin-right: 0px;
      }
    }
    .personal-info-container {
      display: none;
    }
  }
  .sidebar {
    min-width: 300px;

    @media only screen and (min-width: 1200px) {
      max-width: 300px;
      margin-right: 24px;
    }
  }
  .main-content, .view-all-slideshows-main {
    width: calc(100% - 300px);
    .activity-tabs {
      margin-top: 24px;
    }
    .tabs-container {
      .nav-tabs {
        border: none;
        margin: 0;
        overflow: scroll hidden;
        white-space: nowrap;
        display: flex;
        li {
          font-weight: 600;
          font-size: 16px;
          color: $blue-gray-800;

          &.active {
            border-bottom: 2px solid #BD0757;
          }
          a {
            border: none;
            color: $blue-gray-900;
            &:focus {
              outline: none;
            }
          }
        }
      }
      &.profile-tabs {
        margin: 16px 0;
        .nav-tabs {
          li {
            padding: 8px 12px;
            cursor: pointer;
          }
        }
      }
      hr {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid $blue-gray-400;
        margin: 0;
        @media only screen and (max-width: 968px) {
          margin: 0 -16px 18px;
        }
      }
      &.favorites-tabs-container, &.tags-tabs-container, &.downloads-tabs-container  {
        ul {
          display: none;
        }
        & + .sort-buttons {
          position: unset;
          display: flex;
          justify-content: flex-end;
          margin-bottom: 16px;
          @media only screen and (max-width: 600px) {
            margin: 16px 0;
            padding: 0;
          }
        }
        @media only screen and (min-width: 1200px) {
          hr {
            display: none;
          }
        }
      }
    }
    .tab-content {
      display: unset;
      max-width: 100%;
    }
    #block-modal {
      display: none;
    }
    @media only screen and (max-width: 1200px) {
      width: 100%;
    }
  }
  .pagination-container {
    ul {
      box-shadow: none;
    }
    li {
      a {
        margin: 0 4px;
        border-radius: 4px;
        color: #636C8B;
        border: 1px solid #E3E6F0;
        font-weight: 600;
        font-size: 14px;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &.active {
        a {
          border-color: $blue-dark;
        }
      }
      &:hover:not(.disabled):not(.active) {
        a {
          border-color: #8C93B1;
          color: #636C8B;
          background-color: #FFF;
        }
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    display: flex;
    
    .activity-tabs.tabs-container {
      display: none;
    }
    .tab-content {
      display: none;
    }
  }
  @media only screen and (max-width: 1200px) {
    padding: 0 16px;

    &.profile .empty-profile {
      &:not(.mobile-only) {
        display: none !important;
      }
    }
  }
}
.profile-header {
  display: flex;
  flex-wrap: wrap;
  font-family: $font-family;

  .btn-back {
    display: none;
  }
  .user-image {
    width: 80px;
    margin-right: 12px;
    img {
      border-radius: 50%;
    }
  }
  .user-info {
    margin-bottom: 8px;
    .user-name {
      display: block;
      font-weight: 600;
      font-size: 24px;
      &:not(a) {
        color: #212240;
      }
      &:is(a) {
        color: #027Eb0;
      }
    }
    .profile-options-container {
      display: flex;
      align-items: center;
      button {
        height: 32px;
      }
      .dropdown-options {
        margin-left: 18px;
      }
      .dropdown-menu {
        @media only screen and (max-width: 420px) {
          left: -130px;
        }
      }
    }
  }
  .user-stats-amount {
    margin-bottom: 8px;
    flex-basis: 100%;
    a {
      color: $blue-gray-600;
      font-size: 14px;
      display: block;
      font-weight: 600;

      &:hover {
        color: $blue-dark;
      }
      &:active, &.active {
        color: $blue-gray-800;
      }
    }
    &:not(.mobile-only) {
      @media only screen and (max-width: 1200px) {
        display: none;
      }
    }
    @media only screen and (max-width: 480px) {
      justify-content: space-around;
    }
  }
  .edit-profile-btn, .keynote-btn {
    border: $blue-dark solid 1px;
    color: $blue-dark;
    font-weight: 600;
    font-size: 16px;
    border-radius: 4px;
    padding: 6px 12px;

    &:hover {
      text-decoration: none;
      border-color: $celadon-blue-dark;
      background-color: rgba(2, 126, 176, 0.05);
    }
    i {
      margin-right: 8px;
    }
    @media only screen and (max-width: 1200px) {
      padding: 10px 14px;
    }
  }
  .edit-profile-btn {
    display: inline-block;
  }
  .keynote-btn {
    display: block;
    margin-top: 8px;

    @media only screen and (max-width: 1200px) {
      display: inline-block;
      margin-left: 4px;
    }
    @media only screen and (max-width: 420px) {
      span {
        display: none;
      }
    }
  }
  .followers-count {
    display: none;
  }
  & + hr {
    display: none;
  }

  @media only screen and (max-width: 1200px) {
    .user-stats-amount {
      display: flex;
      margin-top: 16px;

      a {
        margin-right: 18px;
      }
      .count-label {
        display: block;
        font-weight: 600;
        font-size: 12px;
      }
      .count {
        display: block;
        font-weight: 600;
        font-size: 20px;
        text-align: center;
        color: $blue-gray-800;
      }
    }

  }
  @media only screen and (max-width: 480px) {
    display: flex;
    align-items: center;

    .user-image {
      display: inline;
      max-width: 40px;
      max-height: 40px;
      height: 40px;
    }
    .user-info {
      display: flex;
      flex: 1;
      align-items: center;

      .button-container {
        margin-left: auto;
      }
    }
  }
}
.sidebar-header {
  font-family: $font-family;
  font-weight: 600;
  font-size: 20px;
  color: $blue-gray-800;
  line-height: 25px;
  margin-bottom: 8px;
}
.tags-container {
  margin-top: 8px;
  &:not(.mobile-only) {
    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }
  .section-container {
    .tags-list {
      position: relative;
      &.truncate {
        &:not(.show-all) {
          max-height: 121px;
          overflow: hidden;
        }
        &.show-all {
          .fade-bottom {
            display: none;
          }
        }
        .fade-bottom {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 30px;
          background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 100%);
        }
      }
    }
    .toggle-more-tags {
      font-weight: 600;
      font-size: 14px;
      color: $blue-dark;

      &.hide {
        display: none;
      }
    }
  }
}
.personal-info-container {
  margin-top: 32px;
  &:not(.mobile-only) {
    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }
}
.sidebar-header {
  font-family: $font-family;
  font-weight: 600;
  font-size: 20px;
  color: $blue-gray-800;
  line-height: 25px;
  margin-bottom: 8px;
}
.section-container {
  margin-bottom: 16px;
}
.sidebar-sub-header {
  font-weight: 600;
  font-size: 14px;
  color: $blue-gray-700;
  line-height: 18px;
}
.sidebar-details {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 27px;
  color: $blue-gray-600;
  i {
    font-size: 14px;
    margin-left: 4px;
  }
}
.contact-container {
  display: flex;
  margin-top: 8px;
  a {
    margin-right: 24px;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.twitter {
      border: 1px solid #1DA1F2;

      i {
        color: #1DA1F2;
      }
    }
    &.facebook {
      border: 1px solid #435E95;

      i {
        color: #435E95;
      }
    }
    &.linkedin {
      border: 1px solid #337AB1;

      i {
        color: #337AB1;
      }
    }
  }
}
.tag-capsule {
  border: 1px solid $blue-gray-400;
  border-radius: 16px;
  color: $blue-gray-600;
  padding: 0 8px;
  display: inline-block;
  font-weight: 600;

  &:nth-child(n + 3) {
    margin: 4px 0;
  }
}
.carousel-container {
  margin-bottom: 32px;
  .carousel-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .carousel-header-count-container {
      .carousel-header {
        color: $blue-gray-900;
        font-weight: 700;
        font-size: 22px;
        margin: 0 4px 0 0;
        display: inline;
      }
      .count {
        color: $blue-gray-600;
        font-size: 16px;
      }
    }
    .see-all {
      color: $blue-dark;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .carousel-contents {
    position: relative;
    .slick-list {
      overflow-x: clip;
    }
    .slick-track {
      display: flex;
    }
    .slick-slide {
      margin-right: 12px;
      & > div {
        height: 100%;
      }
    }
    .paddle {
      -ms-transform: translateY(-50%);
      background-color: #fff;
      border: none;
      height: 44px;
      padding: 8px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 44px;
      z-index: 10;
      background-color: transparent;
      color: $blue-gray-800;

      & span {
        display: inline-block;
        width: 32px;
        height: 32px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
        border-radius: 100%;
        background-size: 8px 14px;
        background-repeat: no-repeat;
        background-position: center;
        background-color: white;
      }

      & span:hover {
        border: 1px solid $primary-color;
      }

      &.paddle-left {
        left: -16px;

        span {
          background-image: url("/images/slideshow/carousel/left_arrow.svg");
        }
      }

      &.paddle-right {
        right: -8px;

        span {
          background-image: url("/images/slideshow/carousel/right_arrow.svg");
        }
      }

      &.slick-disabled {
        display: none !important;
      }
    }
  }
}
.slideshow-card {
  display: block;
  border: 1px solid #E3E6F0;
  border-radius: 8px;
  min-height: 235px;
  height: 100%;

  .bg-img-container {
    max-height: 62px;
    min-height: 62px;

    .thumb {
      height: 150px;
      border-bottom: #F5F6FA solid 1px;
      border-radius: 4px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }
  .title-container {
    background-color: #FFF;
    margin: 12px 16px;
    .title {
      font-weight: 600;
      font-size: 18px;
      color: $blue-gray-800;
      overflow-wrap: anywhere;
      overflow: hidden;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .slideshow-stats-container {
      align-items: center;
      display: flex;
      margin-top: 8px;
      justify-content: space-between;
      .stats {
        font-size: 16px;
        margin-top: 2px;
        color: $blue-gray-600;
      }
      .edit-dropdown {
        display: flex;
        align-items: center;
        color: $blue-gray-600;
        font-size: 16px;
      }
    }
  }
  .dropdown-menu {
    left: -128px;
    z-index: 100;

    &.is-owner {
      top: -205px;
    }
    &:not(.is-owner) {
      top: -60px;
    }
  }
  .slideshow-dropdown {
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  .edit-button {
    @media only screen and (min-width: 601px) {
      display: none;
    }
  }
}
.slideshow-list-container {
  flex-wrap: wrap;
  display: flex;
  &.active {
    display: flex;
  }
  .slideshow-card {
    min-width: 264px;
    max-width: 264px;
    margin-right: 12px;
    margin-bottom: 12px;
  }
}
.superUser {
  margin: 8px 0 24px 0;
  padding: 4px 10px;
  a { 
    display: block; 
    padding: 3px 0 1px; 
    font-size: 11px; 
  }
}
.empty-profile {
  border: 1px solid $blue-gray-300;
  border-radius: 2px;
  padding: 24px;
  color: $blue-gray-700;
  width: 100%;

  &-title {
    font-weight: 600;
    font-size: 26px;
  }
  &-body {
    font-size: 16px;
  }
}
.dropdown-toggle, .edit-button {
  box-shadow: none !important;
  border-radius: 4px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  color: $blue-gray-600;
  padding-left: 8px;
  padding-right: 8px;

  .icon {
    width: auto;
    height: auto;
    background-image: none;
  }
  &:hover {
    text-decoration: none;
  }
}
.dropdown-options {
  margin-left: 18px;
  &.open .dropdown-menu {
    background-color: #FFF;
    bottom: 0;
    display: table;
    margin-top: 8px;
    padding: 0;
    position: absolute;
    a, button {
      padding: 12px 20px;
      color: $blue-gray-800;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      background-color: #FFF;
      border: none;
      margin: 0;
      text-align: left;
      width: 100%;
      &:not(.hide):first-child {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
      &:not(.hide):last-child {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:hover {
        background-color: $blue-gray-100;
      }
    }
  }
  &.open, &:hover {
    .dropdown-toggle {
      background-color: $blue-gray-200;
      box-shadow: none;
    }
  }
  &:hover, &.open {
    .dropdown-toggle {
      background-color: rgba(2, 126, 176, 0.08) !important;
    }
  }
}
.edit-settings-tray {
  left: 0;
  right: 0;
  width: 100%;
  height: 160px;
  bottom: -250px;
  background-color: #FFF;
  color: black;
  z-index: 1001;
  border-radius: 4px 4px 0px 0px;
  padding: 8px 16px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  position: fixed;

  @keyframes slide-up {
    from {
      bottom: -250px;
    }
    to {
      bottom: 0;
    }
  }
  @keyframes slide-down {
    from {
      bottom: 0;
    }
    to {
      bottom: -250px;
    }
  }

  &.show {
    animation: 1s slide-up;
    bottom: 0;
  }
  &.animate {
    animation: 1s slide-down;
  }
  &:not(.show):not(.animate) {
    display: none;
  }
  button, a {
    display: flex;
    align-items: center;
    border: transparent 2px solid;
    background-color: transparent;
    padding: 12px 0;
    margin: 0;
    color: $blue-gray-800;
    font-size: 16px;
    line-height: normal;
    &:focus {
      border: $blue-dark 2px solid;
      i {
        color: #636C8B;
      }
    }
    i {
      font-size: 20px;
      margin-right: 16px;
    }
  }
}
.follow-container {
  .users-following-header {
    color: #0E0F25;
    font-size: 20px;
  }
  .users-card-container {
    display: flex;
    margin-top: 16px;
    flex-wrap: wrap;

    .user-card {
      width: calc(50% - 8px);
      border: 1px solid $blue-gray-200;
      border-radius: 8px;
      background-color: #FFF;
      padding: 8px;
      &:nth-child(odd) {
        margin-right: 16px;
      }
      &:nth-child(n + 3) {
        margin-top: 8px;
      }
      &:hover {
        box-shadow: 0px 0.25px 3px rgba(0, 0, 0, 0.039), 0px 2.75px 9px rgba(0, 0, 0, 0.19);
      }
      @media only screen and (max-width: 540px) {
        border: none;
        width: 100%;
        display: grid;
        grid-template-columns: 48px 1fr auto;
        grid-gap: 8px;
        margin: 0 0 32px 0 !important;
        padding-right: 0;
        padding-left: 0;

        .user-image, .user-stats {
          margin: 0 !important;
        }
        .user-name {
          display: block;
        }
        .user-stats, .bullet {
          display: inline !important;
        }
        .follow-button {
          margin: 0 !important;
        }
      }

      .user-image {
        display: inline;
        margin-right: 8px;
        img {
          max-width: 48px;
          width: 48px;
          max-height: 48px;
          border-radius: 50%;
          vertical-align: top;
        }
      }
      .user-info {
        display: inline-block;
        .user-name {
          color: $blue-gray-800;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
        }
        .user-description {
          color: $blue-gray-600;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          margin-bottom: 4px
        }
        .presentations-count-container + .social-count-container {
          margin-top: 2px;
        }
        .user-stats {
          color: $blue-gray-600;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          a {
            color: $blue-gray-600;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            display: inline;
          }
        }
        .bullet {
          display: none;
          color: $blue-gray-600;
          font-weight: 600;
        }
        @media only screen and (min-width: 540px) {
          max-width: calc(100% - 60px);
          .user-name {
            span {
              text-overflow: ellipsis;
              overflow: hidden;
              display: block;
              white-space: nowrap;
            }
          }
        }
      }
      .follow-button {
        margin: 8px 0 8px 58px;
        align-self: center;
        button {
          padding: 4px 8px;
          height: auto;

        }
        &:not(.following) {
          button {
            border: #027eb0 solid 1px;
            color: #027eb0;
            background-color: #FFF;
            &:hover {
              border-color: $celadon-blue-dark;
              background-color: rgba(2, 126, 176, 0.05);
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 540px) {
    margin-bottom: 32px;
  }
}
.follow-button {
  &.following {
    button {
      background-color: $blue-gray-600;
      color: #FFF;

      &:hover {
        background-color: $blue-gray-700;
      }
      .follow-text {
        display: none
      }
    }
  }
  &:not(.following) {
    button {
      background-color: #027EB0;
      color: #FFF;

      &:hover {
        background-color: $celadon-blue-dark;
      }
      .following-text {
        display: none
      }
    }
  }
}
.followers-main {
  @media only screen and (max-width: 480px) {
    .sidebar {
      .user-image, .user-stats-amount {
        display: none;
      }
      .user-info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
      }
      .followers-count.mobile-only {
        display: unset;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: $blue-gray-600;
      }
      hr {
        box-shadow: 0px 0.25px 1px rgba(0, 0, 0, 0.039), 0px 0.85px 3px rgba(0, 0, 0, 0.19);
        margin: 8px -16px 24px;
        display: block;
      }
    }
  }
}
.followers-main, .view-all-slideshows-main {
  @media only screen and (max-width: 480px) {
    .btn-back {
      color: #636C8B;
      padding: 0 16px 0 8px;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      i {
        height: 16px;
        width: 16px;
      }
    }
  }
}
footer {
  @media only screen and (max-width: 540px) {
    margin-top: 0px !important;
  }
}